import { createContext, FC, useState } from 'react';
import { DisabledBarVenueFieldsProps, BarModel, BarFiltersProps } from '../models/types/bars.types';
import { BarSortingFields, Order } from '../models/types/common.types';

interface IBarsContext {
  barsUpdate: boolean;
  toggleBarsUpdate: () => void;
  toggleBarDetailsUpdate: () => void;
  bars: BarModel[];
  setBars: (val: BarModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: BarSortingFields;
  setOrderBy: (val: BarSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setProcessValues: (val: boolean) => void;
  processValues: boolean;
  barDetailsRefresh: boolean;
  setDisabledVenuesFields: (val: DisabledBarVenueFieldsProps) => void;
  resetCreateBarVenuesFields: () => void;
  disabledVenuesFields: DisabledBarVenueFieldsProps;
  setFilters: (val: BarFiltersProps) => void;
  filters: BarFiltersProps;
  resetFilters: () => void;
}

export const BarsContext = createContext<IBarsContext>({} as IBarsContext);

export const BarsProvider: FC = ({ children }) => {
  const [barsUpdate, setBarsUpdate] = useState(false);
  const [bars, setBars] = useState<BarModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<BarSortingFields>('name');
  const [loading, setLoading] = useState(false);
  const [processValues, setProcessValues] = useState(false);
  const emptyFilters = {
    clientName: undefined,
    venueName: undefined,
    barType: undefined,
    statusType: undefined
  };
  const [filters, setFilters] = useState<BarFiltersProps>(emptyFilters);
  const resetFilters = (): void => {
    setFilters(emptyFilters);
    setPage(0);
    setLimit(20);
  };
  const initialBarVenuesFields: DisabledBarVenueFieldsProps = {
    accessDays: false,
    productDeliveryDays: false,
    accessWindowOpen: false,
    accessWindowClose: false
  };
  const [disabledVenuesFields, setDisabledVenuesFields] = useState<DisabledBarVenueFieldsProps>(initialBarVenuesFields);
  const [barDetailsRefresh, setBarDetailsRefresh] = useState(true);
  const toggleBarUpdate = (): void => setBarsUpdate(!barsUpdate);
  const toggleBarDetailsUpdate = (): void => setBarDetailsRefresh(!barDetailsRefresh);
  const resetCreateBarVenuesFields = (): void => setDisabledVenuesFields(initialBarVenuesFields);

  return (
    <BarsContext.Provider
      value={{
        filters,
        setFilters,
        resetFilters,
        barsUpdate,
        barDetailsRefresh,
        toggleBarsUpdate: toggleBarUpdate,
        toggleBarDetailsUpdate,
        resetCreateBarVenuesFields,
        bars,
        processValues,
        setProcessValues,
        setDisabledVenuesFields,
        disabledVenuesFields,
        setBars,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading
      }}
    >
      {children}
    </BarsContext.Provider>
  );
};
