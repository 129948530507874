import { useContext, useState } from 'react';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GasMeterIcon from '@mui/icons-material/GasMeter';
import HandymanIcon from '@mui/icons-material/Handyman';
import LiquorIcon from '@mui/icons-material/Liquor';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleIcon from '@mui/icons-material/People';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import OpacityIcon from '@mui/icons-material/Opacity';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { Box, Button, Collapse, List, ListItem, styled } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';
import SecurityIcon from '@mui/icons-material/Security';
import { haveGroupAccessToComponent } from '../../../../components/Functions/haveGroupAccessToComponent';
import { useAuthContext } from '../../../../contexts/AuthContext';

export const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

  .MuiListSubheader-root {
    text-transform: uppercase;
    font-family: 'LLBrown_Bold',
    font-size: ${theme.typography.pxToRem(12)};
    color: ${theme.colors.alpha.trueWhite[50]};
    padding: ${theme.spacing(0, 2.5)};
    line-height: 1.4;
  }`
);

export const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding-left: 0;

      > .MuiListItem-root {
        
        margin-bottom: 8px;

        > .MuiButton-root {
          width: auto;
        }

        > button > span:not(.MuiButton-startIcon) {
          opacity: 1;
          transition: opacity .3s;
          animation: menuTextAnimation .3s forwards;
          overflow: hidden;
          white-space: nowrap;
          display: flex;
          align-items: center;
        }

        .av-collapsed & {
          .MuiListItem-root {
            margin-bottom: 4px;
          }

          > button > span:not(.MuiButton-startIcon) {
            opacity: 0;
            display: none;
          }
        }
      }

      .MuiListItem-root {
        padding: 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};
        }

        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 0)};
          padding-left: 14px;
          padding-right: 10px;
          border: 1px solid transparent;
          border-radius: 30px;

          .av-collapsed & {
            min-width: auto;
          }

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.white[100]};
            transition: ${theme.transitions.create(['color'])};
            margin-right: 10px;

            .MuiSvgIcon-root {
              font-size: 32px;
              transition: none;
            }

            .av-collapsed & {
              margin-right: 0;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[100]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: 4px;
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${theme.colors.alpha.white[30]};
            color: ${theme.colors.alpha.trueWhite[100]};
            
            .av-collapsed & {
              border: 1px solid ${theme.colors.alpha.trueWhite[100]};
            }
            
            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: 0 8px;
          }

          .MuiListItem-root {
            padding: 0;
            margin-bottom: ${theme.spacing(1)};

            &.first {
              margin-top: ${theme.spacing(1)};
            }

            &.last {
              margin-bottom: ${theme.spacing(1)};
            }

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 2)};
              margin-left: ${theme.spacing(4)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &.active,
              &:hover {
                &:before {
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }`
);

function SidebarMenu<SidebarMenuProps>({ wide = false }) {
  const { closeSidebar, changeUsersSubMenuToggle, changeBrewersTSPsSubMenuToggle, changeReportsSubMenuToggle } =
    useContext(SidebarContext);
  const [usersMenu, setUsersMenu] = useState(false);
  // const [venuesBarsMenu, setVenuesBarsMenu] = useState(false);
  const [brewersTSPsMenu, setBrewersTSPsMenuMenu] = useState(false);
  const [reportsMenu, setReportsMenu] = useState(false);
  const { auth } = useAuthContext();

  const handleUsersClick = (): void => {
    if (wide) {
      setUsersMenu(!usersMenu);
    } else {
      changeUsersSubMenuToggle();
    }
  };

  // const handleVenuesBarsClick = (): void => {
  //   if (wide) {
  //     setVenuesBarsMenu(!venuesBarsMenu);
  //   } else {
  //     changeVenuesBarsSubMenuToggle();
  //   }
  // };

  const handleBrewersTSPsClick = (): void => {
    if (wide) {
      setBrewersTSPsMenuMenu(!brewersTSPsMenu);
    } else {
      changeBrewersTSPsSubMenuToggle();
    }
  };

  const handleReportsClick = (): void => {
    if (wide) {
      setReportsMenu(!reportsMenu);
    } else {
      changeReportsSubMenuToggle();
    }
  };

  return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  className="level-1"
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/visits"
                  startIcon={<AutoStoriesIcon />}
                >
                  {wide && 'Visits'}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  className="level-1"
                  type="button"
                  onClick={handleUsersClick}
                  startIcon={<ManageAccountsIcon />}
                >
                  <span>Users {usersMenu ? <ExpandLess /> : <ExpandMore />}</span>
                </Button>
              </ListItem>
              {wide && (
                <div>
                  <ListItem component="div">
                    <Collapse in={usersMenu} timeout="auto" unmountOnExit>
                      <ListItem component="div" className="first">
                        <Button
                          disableRipple
                          component={RouterLink}
                          onClick={closeSidebar}
                          to="/users/list"
                          startIcon={<PeopleIcon />}
                        >
                          List
                        </Button>
                      </ListItem>
                    </Collapse>
                  </ListItem>
                  {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
                    <ListItem component="div">
                      <Collapse in={usersMenu} timeout="auto" unmountOnExit>
                        <ListItem component="div">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/users/roles"
                            startIcon={<SecurityIcon />}
                          >
                            Roles
                          </Button>
                        </ListItem>
                      </Collapse>
                    </ListItem>
                  )}
                  {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
                    <ListItem component="div">
                      <Collapse in={usersMenu} timeout="auto" unmountOnExit>
                        <ListItem component="div" className="last">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/users/permissions"
                            startIcon={<VerifiedUserIcon />}
                          >
                            Permissions
                          </Button>
                        </ListItem>
                      </Collapse>
                    </ListItem>
                  )}
                </div>
              )}
            </List>
          </SubMenuWrapper>
        </List>

        <List component="div">
          <SubMenuWrapper>
            <List component="div">
              <ListItem component="div">
                <Button
                  disableRipple
                  className="level-1"
                  component={RouterLink}
                  onClick={closeSidebar}
                  to="/venues/list"
                  startIcon={<LocationOnIcon />}
                >
                  {wide && 'Venues'}
                </Button>
              </ListItem>
            </List>
          </SubMenuWrapper>
        </List>

        {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    className="level-1"
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/clients"
                    startIcon={<PeopleAltIcon />}
                  >
                    {wide && 'Clients'}
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    className="level-1"
                    type="button"
                    onClick={handleBrewersTSPsClick}
                    startIcon={<GasMeterIcon />}
                    style={{ display: 'flex', flexWrap: 'nowrap' }}
                  >
                    <span>Brewers {brewersTSPsMenu ? <ExpandLess /> : <ExpandMore />}</span>
                  </Button>
                </ListItem>
                {wide && (
                  <div>
                    <ListItem component="div">
                      <Collapse in={brewersTSPsMenu} timeout="auto" unmountOnExit>
                        <ListItem component="div" className="first">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/brewers"
                            startIcon={<GasMeterIcon />}
                          >
                            {wide && 'Brewers'}
                          </Button>
                        </ListItem>
                      </Collapse>
                    </ListItem>

                    <ListItem component="div">
                      <Collapse in={brewersTSPsMenu} timeout="auto" unmountOnExit>
                        <ListItem component="div" className="last">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/technical-support-providers/list"
                            startIcon={<HandymanIcon />}
                          >
                            TSPs
                          </Button>
                        </ListItem>
                      </Collapse>
                    </ListItem>

                    <ListItem component="div">
                      <Collapse in={brewersTSPsMenu} timeout="auto" unmountOnExit>
                        <ListItem component="div" className="last">
                          <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to="/ams/list"
                            startIcon={<PlumbingIcon />}
                          >
                            AMS Parts
                          </Button>
                        </ListItem>
                      </Collapse>
                    </ListItem>
                  </div>
                )}
              </List>
            </SubMenuWrapper>
          </List>
        )}
        {auth && auth.significantGroup && haveGroupAccessToComponent(['avani'], auth.significantGroup) && (
          <List component="div">
            <SubMenuWrapper>
              <List component="div">
                <ListItem component="div">
                  <Button
                    disableRipple
                    className="level-1"
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/products"
                    startIcon={<LiquorIcon />}
                  >
                    {wide && 'Products'}
                  </Button>
                </ListItem>
                <ListItem component="div">
                  <Button
                    disableRipple
                    className="level-1"
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/ref-materials"
                    startIcon={<PlagiarismIcon />}
                  >
                    {wide && 'Reference Materials'}
                  </Button>
                </ListItem>
                <SubMenuWrapper>
                  <List component="div">
                    <ListItem component="div">
                      <Button
                        disableRipple
                        className="level-1"
                        type="button"
                        onClick={handleReportsClick}
                        startIcon={<GasMeterIcon />}
                        style={{ display: 'flex', flexWrap: 'nowrap' }}
                      >
                        <span>Reports {reportsMenu ? <ExpandLess /> : <ExpandMore />}</span>
                      </Button>
                    </ListItem>
                    {wide && (
                      <div>
                        <ListItem component="div">
                          <Collapse in={reportsMenu} timeout="auto" unmountOnExit>
                            <ListItem component="div" className="first">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/reports/visits-general"
                                startIcon={<AutoStoriesIcon />}
                              >
                                {wide && 'Visits General'}
                              </Button>
                            </ListItem>
                          </Collapse>
                        </ListItem>

                        <ListItem component="div">
                          <Collapse in={reportsMenu} timeout="auto" unmountOnExit>
                            <ListItem component="div" className="last">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/reports/client-estate"
                                startIcon={<PeopleAltIcon />}
                              >
                                {wide && 'Client Estate'}
                              </Button>
                            </ListItem>
                          </Collapse>
                        </ListItem>

                        <ListItem component="div">
                          <Collapse in={reportsMenu} timeout="auto" unmountOnExit>
                            <ListItem component="div" className="last">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/reports/visits-client"
                                startIcon={<AutoStoriesIcon />}
                              >
                                {wide && 'Visits Client'}
                              </Button>
                            </ListItem>
                          </Collapse>
                        </ListItem>

                        <ListItem component="div">
                          <Collapse in={reportsMenu} timeout="auto" unmountOnExit>
                            <ListItem component="div" className="last">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/reports/ams-parts"
                                startIcon={<PlumbingIcon />}
                              >
                                {wide && 'Ams Parts Used'}
                              </Button>
                            </ListItem>
                          </Collapse>
                        </ListItem>

                        <ListItem component="div">
                          <Collapse in={reportsMenu} timeout="auto" unmountOnExit>
                            <ListItem component="div" className="last">
                              <Button
                                disableRipple
                                component={RouterLink}
                                onClick={closeSidebar}
                                to="/reports/visit-taps"
                                startIcon={<OpacityIcon />}
                              >
                                {wide && 'Wastage'}
                              </Button>
                            </ListItem>
                          </Collapse>
                        </ListItem>
                      </div>
                    )}
                  </List>
                </SubMenuWrapper>
                <ListItem component="div">
                  <Button
                    disableRipple
                    className="level-1"
                    component={RouterLink}
                    onClick={closeSidebar}
                    to="/settings/legals"
                    startIcon={<BrightnessLowTwoToneIcon />}
                  >
                    {wide && 'Settings'}
                  </Button>
                </ListItem>
              </List>
            </SubMenuWrapper>
          </List>
        )}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
