import { createContext, FC, useState } from 'react';
import { VisitModel } from '../models/types/visits.types';

interface IVisitsContext {
  visit: VisitModel | null;
  setVisit: (val: VisitModel) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  refreshData: boolean;
  setRefreshData: (val: boolean) => void;
  toggleRefresh: () => void;
  selectedTabIndex: number;
  setSelectedTabIndex: (val: number) => void;
  // visitBars: Record<string, VisitBarParams>;
  // setVisitBars: (val: Record<string, VisitBarParams>) => void;
  // visitTaps: Record<string, Record<string, VisitTapParams>>;
  // setVisitTaps: (val: Record<string, Record<string, VisitTapParams>>) => void;
  // visitGlassWashers: Record<string, Record<string, VisitGlassWasherParams>>;
  // setVisitGlassWashers: (val: Record<string, Record<string, VisitGlassWasherParams>>) => void;
  // visitCellars: VisitCellarModel[] | null;
  // setVisitCellars: (val: VisitCellarModel[]) => void;
  // visitFobs: Record<string, Record<string, VisitFobParams>>;
  // setVisitFobs: (val: Record<string, Record<string, VisitFobParams>>) => void;
  // visitRemotes: Record<string, Record<string, VisitRemoteCoolerParams>>;
  // setVisitRemotes: (val: Record<string, Record<string, VisitRemoteCoolerParams>>) => void;
}

export const VisitsContext = createContext<IVisitsContext>({} as IVisitsContext);

export const VisitsProvider: FC = ({ children }) => {
  const [visit, setVisit] = useState<VisitModel | null>(null);
  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  // const [visitBars, setVisitBars] = useState<Record<string, VisitBarParams>>({});
  // const [visitTaps, setVisitTaps] = useState<Record<string, Record<string, VisitTapParams>>>({});
  // const [visitGlassWashers, setVisitGlassWashers] = useState<Record<string, Record<string, VisitGlassWasherParams>>>(
  //   {}
  // );
  // const [visitCellars, setVisitCellars] = useState<VisitCellarModel[] | null>(null);
  // const [visitFobs, setVisitFobs] = useState<Record<string, Record<string, VisitFobParams>>>({});
  // const [visitRemotes, setVisitRemotes] = useState<Record<string, Record<string, VisitRemoteCoolerParams>>>({});

  function toggleRefresh() {
    setRefreshData(!refreshData);
  }

  return (
    <VisitsContext.Provider
      value={{
        // visitCellars,
        // setVisitCellars,
        // visitFobs,
        // setVisitFobs,
        // visitRemotes,
        // setVisitRemotes,
        // visitBars,
        // setVisitBars,
        // visitTaps,
        // setVisitTaps,
        // visitGlassWashers,
        // setVisitGlassWashers,
        selectedTabIndex,
        setSelectedTabIndex,
        visit,
        setVisit,
        loading,
        setLoading,
        refreshData,
        setRefreshData,
        toggleRefresh
      }}
    >
      {children}
    </VisitsContext.Provider>
  );
};
