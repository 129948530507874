import { VisitAmsPartModel } from './visits-ams-part.types';
import { BaseModel } from './common.types';
import { CommentModel } from './comments.types';
import { FaultInteractionData, VisitFaultInteractionsModel } from './visits-fault-interactions.types';

export interface VisitFaultModel extends BaseModel {
  visit: string;
  visitFaultInteractions: VisitFaultInteractionsModel[];
  faultCategory: FaultCategory;
  faultType?: FaultType;
  customName?: string;
  isCritical: boolean;
  isReported: boolean;
  isResolved: boolean;
  resolvedAt?: string;
  amsPartsUsed: boolean;
  isPendingRevision: boolean;
  // Related Model data
  relatedEntityId?: string;
  relatedEntityName?: string;
  relatedEntityType?: RelatedEntityType;
  visitAmsParts: VisitAmsPartModel[];
  comments: CommentModel[];
  faultInteractionsData: FaultInteractionData[];
}

export type ExtendedVisitFaultModel = VisitFaultModel & {
  visitBarId?: string;
  visitTapId?: string;
  visitCellarId?: string;
  visitRemoteCoolerId?: string;
  visitFobId?: string;
  visitGlassWasherId?: string;
};

export enum RelatedEntityType {
  VisitBar = 'VisitBar',
  VisitTap = 'VisitTap',
  VisitCellar = 'VisitCellar',
  VisitRemoteCooler = 'VisitRemoteCooler',
  VisitFob = 'VisitFob',
  VisitGlassWasher = 'VisitGlassWasher',
  Other = 'Other'
}

export enum FaultCategory {
  Technical = 'Technical',
  Operational = 'Operational'
}

export enum OperationalFaultType {
  BackboardHygiene = 'Backboard Hygiene',
  CouplerHygiene = 'Coupler Hygiene',
  GlassWashDetergentRinseaid = 'Glass Wash Detergent / Rinseaid',
  GlassWasherCare = 'Glass Washer Care',
  GlasswareHygiene = 'Glassware Hygiene',
  GlasswareStorage = 'Glassware Storage',
  NozzleHygiene = 'Nozzle Hygiene',
  ObstructionBlockedAirflow = 'Obstruction / Blocked Airflow',
  OtherHygiene = 'Other Hygiene',
  PourTechnique = 'Pour Technique',
  StockControl = 'Stock Control',
  GlassWasherChemicals = 'Glass Washer Chemicals',
  GlassWasherMaintenance = 'Glass Washer Maintenance',
  CellarManagement = 'Cellar Management',
  KegChange = 'Keg Change',
  FobControl = 'FOB Control',
  CaskProcess = 'Cask Process',
  RiskAssessment = 'Risk Assessment',
  Other = 'Other'
}

export enum TechnicalFaultVisitBarType {
  ExposedLines = 'Exposed Lines',
  DamagedInsulation = 'Damaged Insulation',
  ContaminatedInsulation = 'Contaminated Insulation',
  ExposedFittings = 'Exposed Fittings',
  ExcessiveRun = 'Excessive Run'
}

export enum TechnicalFaultVisitTapType {
  ProductLineOtherTaint = 'Product Line - Other Taint',
  ProductLineChlorophenolTaint = 'Product Line - Chlorophenol Taint',
  ProductLineWaterTaint = 'Product Line - Water Taint',
  ProductLineOxidationTaint = 'Product Line - Oxidation Taint',
  ProductLineSpoilageTaint = 'Product Line - Spoilage Taint',
  FlowControllerLeaking = 'Flow Controller - Leaking',
  FlowControllerBlocked = 'Flow Controller - Blocked',
  FlowControllerFlowIssue = 'Flow Controller - Flow Issue',
  FlowControllerJammed = 'Flow Controller - Jammed',
  FontTBarMountingFault = 'Font / T-bar - Mounting Fault',
  FontTBarLeaking = 'Font / T-bar - Leaking',
  FontTBarWearTear = 'Font / T-bar - Wear & Tear',
  FontTBarBranding = 'Font / T-bar - Branding',
  TapBodyDamagedGaskets = 'Tap Body - Damaged Gaskets',
  TapBodyCompensatorFault = 'Tap Body - Compensator Fault',
  TapBodyMountingNutSlack = 'Tap Body - Mounting Nut Slack',
  TapBodyWornMountingThreads = 'Tap Body - Worn Mounting Threads',
  TapBodyDisplacedMissingHingePin = 'Tap Body - Displaced / Missing Hinge Pin',
  TapBodyHandleOperation = 'Tap Body - Handle Operation',
  TapBodyTapSealTypeIncorrect = 'Tap Body - Tap Seal Type Incorrect',
  TapBodyTapSealDamaged = 'Tap Body - Tap Seal Damaged',
  TapBodyLeaking = 'Tap Body - Leaking',
  TapBodyBlocked = 'Tap Body - Blocked',
  NozzleDamagedWorn = 'Nozzle - Damaged / Worn',
  NozzlePlasticDelamination = 'Nozzle - Plastic Delamination',
  NozzleMissing = 'Nozzle - Missing',
  NozzleCreamerPartsMissingDamaged = 'Nozzle - Creamer Parts Missing / Damaged'
}

export enum TechnicalFaultVisitGlassWasherType {
  Corrosion = 'Corrosion',
  MissingPanels = 'Missing Panels',
  ChemicalDosingPumps = 'Chemical Dosing Pumps',
  FiltersDamagedMissing = 'Filters Damaged / Missing',
  SprayArmsDamagedMissing = 'Spray Arms Damaged / Missing',
  DrainageFailure = 'Drainage Failure',
  TemperatureFailure = 'Temperature Failure',
  WaterPumpFailure = 'Water Pump Failure',
  PowerFailure = 'Power Failure'
}

export enum TechnicalFaultVisitCellarType {
  GasCylinderSupplyDamagedFaulty = 'Gas Cylinder / Supply - Damaged / Faulty',
  GasCylinderSupplyExcessiveDischargeFrosting = 'Gas Cylinder / Supply - Excessive Discharge/ Frosting',
  PrimaryGasRegulatorRegulationFailure = 'Primary Gas Regulator - Regulation Failure',
  PrimaryGasRegulatorCylinderHoseDamaged = 'Primary Gas Regulator - Cylinder Hose Damaged',
  PrimaryGasRegulatorSealMissingDamaged = 'Primary Gas Regulator - Seal Missing / Damaged',
  PrimaryGasRegulatorFittingsDamagedLeaking = 'Primary Gas Regulator - Fittings Damaged / Leaking',
  PrimaryGasRegulatorPressureReliefValveFailure = 'Primary Gas Regulator - Pressure Relief Valve Failure',
  PrimaryGasRegulatorFreezingOver = 'Primary Gas Regulator - Freezing Over',
  PrimaryGasRegulatorPressureLimitIncorrect = 'Primary Gas Regulator - Pressure Limit Incorrect',
  GasBlenderPowerFailure = 'Gas Blender - Power Failure',
  GasBlenderGasLeak = 'Gas Blender - Gas Leak',
  GasBlenderMaxOutputPressureLowHigh = 'Gas Blender - Max Output Pressure Low / High',
  GasBlenderN2Production = 'Gas Blender - N2 Production',
  GasBlenderN2Storage = 'Gas Blender - N2 Storage',
  GasBlenderUnitTurnedOff = 'Gas Blender - Unit Turned Off',
  GasBlenderGeneralFault = 'Gas Blender - General Fault',
  CompressorOverloading = 'Compressor - Overloading',
  CompressorMountingDamaged = 'Compressor - Mounting Damaged',
  CompressorPowerFailure = 'Compressor - Power Failure',
  CompressorLeaking = 'Compressor - Leaking',
  CompressorPressureRegulatorFailure = 'Compressor - Pressure Regulator Failure',
  CompressorOutputPressureLowHigh = 'Compressor - Output Pressure Low / High',
  CompressorPressureReliefValveFailure = 'Compressor - Pressure Relief Valve Failure',
  GasRingmainRingmainLoopIncomplete = 'Gas Ringmain - Ringmain Loop Incomplete',
  GasRingmainMaxPressureLowHigh = 'Gas Ringmain - Max Pressure Low / High',
  GasRingmainLeaking = 'Gas Ringmain - Leaking',
  GasRingmainLineDamaged = 'Gas Ringmain - Line Damaged',
  GasRingmainExhaustNotVented = 'Gas Ringmain - Exhaust Not Vented',
  GasRingmainReliefValveMissing = 'Gas Ringmain - Relief Valve Missing',
  GasRingmainOverloading = 'Gas Ringmain - Overloading',
  TemperatureProofingExcessiveHeatSources = 'Temperature Proofing - Excessive Heat Sources',
  TemperatureProofingDoorInsulationDamagedMissing = 'Temperature Proofing - Door Insulation Damaged / Missing',
  TemperatureProofingDoorDamaged = 'Temperature Proofing - Door Damaged ',
  TemperatureProofingDoorFailsToClose = 'Temperature Proofing - Door Fails To Close',
  TemperatureProofingKegHatchExposed = 'Temperature Proofing - Keg Hatch Exposed',
  TemperatureProofingCeilingHeightExcessive = 'Temperature Proofing - Ceiling Height Excessive',
  TemperatureProofingCeilingIncomplete = 'Temperature Proofing - Ceiling Incomplete',
  TemperatureProofingCellarWallInsulationInsufficient = 'Temperature Proofing - Cellar Wall Insulation Insufficient',
  CleaningBellDamaged = 'Cleaning Bell - Damaged',
  CleaningBellExcessiveStaining = 'Cleaning Bell - Excessive Staining',
  CleaningBellLeaking = 'Cleaning Bell - Leaking',
  CleaningBellMissing = 'Cleaning Bell - Missing',
  CleaningPumpGeneralFault = 'Cleaning Pump - General Fault',
  CleaningPumpDiaphragmLeaking = 'Cleaning Pump - Diaphragm Leaking',
  CleaningPumpGasPressureIncorrect = 'Cleaning Pump - Gas Pressure Incorrect',
  CleaningPumpFittingsDamagedLeaking = 'Cleaning Pump - Fittings Damaged / Leaking',
  CleaningPumpGasExhaustNotVented = 'Cleaning Pump - Gas Exhaust Not Vented',
  CleaningPumpGasExhaustSaturated = 'Cleaning Pump - Gas Exhaust Saturated',
  CleaningPumpPumpMissing = 'Cleaning Pump - Pump Missing',
  CleaningRingmainLineDamagedLeaking = 'Cleaning Ringmain - Line Damaged / Leaking',
  CleaningRingmainExcessiveStaining = 'Cleaning Ringmain - Excessive Staining',
  CleaningRingmainMineralDeposits = 'Cleaning Ringmain - Mineral Deposits',
  CleaningRingmainLineBalancingInconsistent = 'Cleaning Ringmain - Line Balancing Inconsistent',
  CleaningRingmainLineGaugeInconsistent = 'Cleaning Ringmain - Line Gauge Inconsistent',
  CleaningRingmainAirIngress = 'Cleaning Ringmain - Air Ingress',
  CleaningRingmainOverloading = 'Cleaning Ringmain - Overloading',
  CleaningRingmainMissingUndersized = 'Cleaning Ringmain - Missing / Undersized'
}

export enum TechnicalFaultVisitFobType {
  CleaningSocketCorrosion = 'Cleaning Socket - Corrosion',
  CleaningSocketSealDamagedPerished = 'Cleaning Socket - Seal Damaged / Perished',
  CleaningSocketConnectionDamaged = 'Cleaning Socket - Connection Damaged',
  CleaningSocketIncorrectType = 'Cleaning Socket - Incorrect Type',
  CleaningSocketLeaking = 'Cleaning Socket - Leaking',
  CleaningSocketChemicalSoiling = 'Cleaning Socket - Chemical Soiling',
  KegProductConnectionSurfaceDamaged = 'Keg / Product - Connection Surface Damaged',
  KegProductIncorrectGas = 'Keg / Product - Incorrect Gas',
  KegProductProductSpoilage = 'Keg / Product - Product Spoilage',
  KegProductSealDamagedPerished = 'Keg / Product - Seal Damaged / Perished',
  CouplerNonReturnValveDamagedMissing = 'Coupler - Non Return Valve Damaged / Missing',
  CouplerSealsDamagedMissing = 'Coupler - Seals Damaged / Missing',
  CouplerFittingsDamaged = 'Coupler - Fittings Damaged',
  CouplerConnectionSurfaceDamaged = 'Coupler - Connection Surface Damaged',
  DropLineBeerLineDamaged = 'Drop Line - Beer Line Damaged',
  DropLineGasLineDamaged = 'Drop Line - Gas Line Damaged',
  DropLineFittingWearTear = 'Drop Line - Fitting Wear & Tear',
  DropLineLengthExcessiveShort = 'Drop Line - Length Excessive / Short',
  BeerPumpGeneralFault = 'Beer Pump - General Fault',
  BeerPumpDiaphragmLeaking = 'Beer Pump - Diaphragm Leaking',
  BeerPumpTriggeringExcessively = 'Beer Pump - Triggering Excessively',
  BeerPumpGasPressureIncorrect = 'Beer Pump - Gas Pressure Incorrect',
  BeerPumpFittingsDamagedLeaking = 'Beer Pump - Fittings Damaged / Leaking',
  BeerPumpGasExhaustNotVented = 'Beer Pump - Gas Exhaust Not Vented',
  BeerPumpGasExhaustSaturated = 'Beer Pump - Gas Exhaust Saturated',
  SecondaryGasRegulatorGasEquilibriumPressureOutOfRange = 'Secondary Gas Regulator - Gas Equilibrium Pressure Out of Range',
  SecondaryGasRegulatorGasPumpPressureIncorrect = 'Secondary Gas Regulator - Gas Pump Pressure Incorrect',
  SecondaryGasRegulatorLeaking = 'Secondary Gas Regulator - Leaking',
  SecondaryGasRegulatorPressureCreep = 'Secondary Gas Regulator - Pressure Creep',
  FobChamberWallsDamagedCracked = 'FOB Chamber - Walls Damaged / Cracked',
  FobChamberMountingDamaged = 'FOB Chamber - Mounting Damaged',
  FobChamberFloatDamaged = 'FOB Chamber - Float Damaged',
  FobChamberFloatReleaseDamaged = 'FOB Chamber - Float Release Damaged',
  FobChamberFittingsDamagedLeaking = 'FOB Chamber - Fittings Damaged / Leaking',
  FobChamberStaining = 'FOB Chamber - Staining',
  FobChamberExhaustValveDamagedLeaking = 'FOB Chamber - Exhaust Valve Damaged / Leaking',
  FobChamberSealsDamagedPerished = 'FOB Chamber - Seals Damaged / Perished',
  FobChamberProductLevelDropping = 'FOB Chamber - Product Level Dropping',
  FeedLineLineDamaged = 'Feed Line - Line Damaged',
  FeedLineInsulationDamagedMissing = 'Feed Line - Insulation Damaged / Missing',
  FeedLineExcessiveFittings = 'Feed Line - Excessive Fittings',
  FeedLineFittingLeaking = 'Feed Line - Fitting Leaking'
}

export enum TechnicalFaultVisitRemoteCoolerType {
  GeneralGeneralFault = 'General - General Fault',
  GeneralIntegralUnitVentilation = 'General - Integral Unit Ventilation',
  GeneralExcessiveLoading = 'General - Excessive Loading',
  WaterBathContamination = 'Water Bath - Contamination',
  WaterBathTemperatureHigh = 'Water Bath - Temperature High',
  WaterBathIceBankAbsent = 'Water Bath - Ice Bank Absent',
  WaterBathIceBankExcessive = 'Water Bath - Ice Bank Excessive',
  WaterBathWaterLevelLow = 'Water Bath - Water Level Low',
  TopPumpGeneralFailure = 'Top Pump - General Failure',
  TopPumpPowerSupplyDamaged = 'Top Pump - Power Supply Damaged',
  TopPumpPumpSeizedInterference = 'Top Pump - Pump Seized / Interference',
  TopPumpPumpOverheating = 'Top Pump - Pump Overheating',
  TopPumpRecirculationRateIncorrect = 'Top Pump - Recirculation Rate Incorrect',
  ProductCoilDamagedRestriction = 'Product Coil - Damaged / Restriction',
  ProductCoilConnectorDamagedLeaking = 'Product Coil - Connector Damaged / Leaking',
  HeatDumpSplitGeneralFailure = 'Heat Dump (Split) - General Failure',
  HeatDumpSplitNoHeatTransfer = 'Heat Dump (Split) - No Heat Transfer',
  HeatDumpSplitFanDamaged = 'Heat Dump (Split) - Fan Damaged',
  HeatDumpSplitRadiatorDamaged = 'Heat Dump (Split) - Radiator Damaged',
  HeatDumpSplitMountingDamaged = 'Heat Dump (Split) - Mounting Damaged',
  IntegralRadiatorGrilleDamaged = 'Integral Radiator - Grille Damaged',
  GlycolReservoirGlycolLevelLow = 'Glycol Reservoir - Glycol Level Low',
  FeedPythonInsulationMissing = 'Feed Python - Insulation Missing',
  FeedPythonNotBackCooledAmbientInstall = 'Feed Python - Not Back Cooled (ambient install)'
}

export type FaultType =
  | OperationalFaultType
  | TechnicalFaultVisitBarType
  | TechnicalFaultVisitTapType
  | TechnicalFaultVisitGlassWasherType
  | TechnicalFaultVisitCellarType
  | TechnicalFaultVisitFobType
  | TechnicalFaultVisitRemoteCoolerType;
