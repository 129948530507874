import { useContext } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { MenuTwoTone } from '@mui/icons-material';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HeaderMenu from './Menu';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme?.header?.background || theme.colors.alpha.black[100], 0.95)};
        backdrop-filter: blur(3px);
        position: 'sticky';
        justify-content: space-between;
         @media (min-width: ${theme.breakpoints.values.lg}px) {
            /*left: ${theme.sidebar.width};*/
            width: 100%;
        }
`
);

function Header() {
  const { setCollapsed, collapsed } = useContext(SidebarContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <HeaderWrapper
      display="flex"
      alignItems="center"
      sx={{
        boxShadow:
          theme.palette.mode === 'dark'
            ? `0 1px 0 ${alpha(
                lighten(theme.colors.primary.main, 0.7),
                0.15
              )}, 0px 2px 8px -3px rgba(0, 0, 0, 0.2), 0px 5px 22px -4px rgba(0, 0, 0, .1)`
            : `0px 2px 8px -3px ${alpha(theme.colors.alpha.black[100], 0.2)}, 0px 5px 22px -4px ${alpha(
                theme.colors.alpha.black[100],
                0.1
              )}`
      }}
    >
      <Box
        component="span"
        sx={{
          ml: 2,
          display: { lg: 'none', xs: 'inline-block' }
        }}
      >
        <Tooltip arrow title="Toggle Menu">
          <IconButton color="primary" onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? (
              <MenuTwoTone fontSize="large" color={'aTeal'} />
            ) : (
              <CloseTwoToneIcon color={'aTeal'} fontSize="large" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      {(!isMobile || (isMobile && collapsed)) && (
        <>
          <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} alignItems="center" spacing={2}>
            <HeaderMenu />
          </Stack>
          <Box display="flex" alignItems="center">
            <HeaderButtons />
            <HeaderUserbox />
          </Box>
        </>
      )}
    </HeaderWrapper>
  );
}

export default Header;
