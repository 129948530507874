import { useContext } from 'react';

import { NavLink as RouterLink } from 'react-router-dom';

import { Box, Button, Card, List, ListItem, styled } from '@mui/material';

import { SidebarContext } from 'src/contexts/SidebarContext';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import OpacityIcon from '@mui/icons-material/Opacity';

const StyledSubMenu = styled(Card)(
  ({ theme }) => `
  position: absolute;
  top: 160px;
  left: 80px;
  width: 250px;
  background-color: ${theme.colors.secondary.light};

  & .MuiButtonBase-root {
    color: ${theme.colors.alpha.white[100]}; 
    width: 100%;
    justify-content: left;

    &:hover {
      color: ${theme.colors.primary.main}
    }
  }
  `
);

function CollapsedUsersSubMenu() {
  const { closeSidebar, changeReportsSubMenuToggle } = useContext(SidebarContext);

  return (
    <>
      <Box
        onClick={() => changeReportsSubMenuToggle()}
        sx={{
          position: 'absolute',
          zIndex: 7,
          width: '100%',
          height: '100%'
        }}
      >
        <StyledSubMenu>
          <List component="div">
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/reports/visits-general"
                startIcon={<AutoStoriesIcon />}
              >
                Visits General
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/reports/client-estate"
                startIcon={<PeopleAltIcon />}
              >
                Client Estate
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/reports/visits-client"
                startIcon={<AutoStoriesIcon />}
              >
                Visits Client
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/reports/ams-parts"
                startIcon={<PlumbingIcon />}
              >
                Ams Parts Used
              </Button>
            </ListItem>
            <ListItem component="div">
              <Button
                disableRipple
                component={RouterLink}
                onClick={closeSidebar}
                to="/reports/visit-taps"
                startIcon={<OpacityIcon />}
              >
                Wastage
              </Button>
            </ListItem>
          </List>
        </StyledSubMenu>
      </Box>
    </>
  );
}

export default CollapsedUsersSubMenu;
