import { Loader } from './loader.routes';
import { lazy } from 'react';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ProtectedRoute from './Components/ProtectedRoute';
import { VisitDashboardProvider } from 'src/contexts/VisitDashboardContext';
import { FaultsDashboardsProvider } from '../../contexts/FaultsDashboardContext';

const ClientDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ClientDashboard')));
const FaultsDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/FaultsDashboard')));
const ProCleanDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProCleanDashboard')));
const ProCleanPlusDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProCleanPLUSDashboard')));

const Sos1Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS1Dashboard')));
const Sos2Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS2Dashboard')));
const Sos3Dashboard = Loader(lazy(() => import('src/content/pages/Dashboards/SOS3Dashboard')));

const WinterisationDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/WinterisationDashboard')));
const DewinterisationDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/DewinterisationDashboard')));
const ChillPourDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ChillPourDashboard')));
//const ProSpecDashboard = Loader(lazy(() => import('src/content/pages/Dashboards/ProSpecDashboard')));

export const createDashboardRoutes = (groupRequired?: null | string, roleRequired?: null | string[]) => ({
  path: 'dashboard',
  element: <SidebarLayout />,
  children: [
    {
      path: 'proclean-plus/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <ProCleanPlusDashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'proclean/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <ProCleanDashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'client',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ClientDashboard />
        </ProtectedRoute>
      )
    },
    {
      path: 'faults/:visitFaultId',
      element: (
        <ProtectedRoute groupRequired={['avani', 'clients']} roleRequired={['*']}>
          <FaultsDashboardsProvider>
            <FaultsDashboard />
          </FaultsDashboardsProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'sos-lv1/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <Sos1Dashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'sos-lv2/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <Sos2Dashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'sos-lv3/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <Sos3Dashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'winterization/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <WinterisationDashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'dewinterization/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <DewinterisationDashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    },
    {
      path: 'chill-pour/:visitId',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <VisitDashboardProvider>
            <ChillPourDashboard />
          </VisitDashboardProvider>
        </ProtectedRoute>
      )
    }
    /*{
      path: 'pro-spec',
      element: (
        <ProtectedRoute groupRequired={['avani']} roleRequired={['*']}>
          <ProSpecDashboard />
        </ProtectedRoute>
      )
    }, */
  ]
});
