import styled, { CSSObject } from '@emotion/styled';
import { Auth, Storage } from 'aws-amplify';
import classnames from 'classnames';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from 'src/contexts/AuthContext';
import Scrollbar from 'src/layouts/LayoutComponents/Scrollbar';
import { getUserInfo } from 'src/models/api-calls/users.api';
import { SidebarContext } from '../../../contexts/SidebarContext';
import SidebarMenu from './SidebarMenu';

const sidebarClasses = {
  root: 'av-sidebar-root',
  container: 'av-sidebar-container',
  collapsed: 'av-collapsed'
};

export interface SidebarProps extends React.HTMLAttributes<HTMLHtmlElement> {
  /**
   * width of the sidebar
   * @default ```250px```
   */
  width?: string;

  /**
   * width of the sidebar when collapsed
   * @default ```80px```
   */
  collapsedWidth?: string;

  /**
   * sidebar background color
   */
  backgroundColor?: string;

  /**
   * duration for the transition in milliseconds to be used in collapse and toggle behavior
   * @default ```300```
   */
  transitionDuration?: number;

  /**
   * sidebar styles to be applied from the root element
   */
  rootStyles?: CSSObject;

  children?: React.ReactNode;
}

interface StyledSidebarProps extends Omit<SidebarProps, 'backgroundColor'> {
  collapsed?: boolean;
  collapsedWidth?: string;
  toggled?: boolean;
  broken?: boolean;
  rtl?: boolean;
}

type StyledSidebarContainerProps = Pick<SidebarProps, 'backgroundColor'>;

const StyledSidebar = styled.aside<StyledSidebarProps>`
  position: relative;
  z-index: 6;
  top: 0;
  left: 0;
  height: 100vh;

  transition: ${({ transitionDuration }) => `width, left, right, ${transitionDuration}ms`};

  width: ${({ width }) => width};
  min-width: ${({ width }) => width};

  &.${sidebarClasses.collapsed} {
    width: ${({ collapsedWidth }) => collapsedWidth};
    min-width: ${({ collapsedWidth }) => collapsedWidth};
  }

  ${({ rootStyles }) => rootStyles}
`;

const StyledSidebarContainer = styled.div<StyledSidebarContainerProps>`
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;

  ${({ backgroundColor }) => (backgroundColor ? `background-color:${backgroundColor};` : '')}
`;

export const Sidebar = React.forwardRef<HTMLHtmlElement, SidebarProps>(
  (
    {
      width = '250px',
      collapsedWidth = '80px',
      className,
      children,
      backgroundColor = '#005665',
      transitionDuration = 300,
      rootStyles,
      ...rest
    },
    ref
  ) => {
    const [mounted] = React.useState(false);
    const { setLoading, setAwsAuth, setAuth, profilePicture, setProfilePicture, auth } = useContext(AuthContext);
    const { collapsed } = useContext(SidebarContext);

    useEffect(() => {
      (async () => {
        setLoading(true);
        try {
          const user = await Auth.currentAuthenticatedUser();
          setAwsAuth(user.attributes);
          if ('custom:db_id' in user.attributes && auth === undefined) {
            const userInfo = await getUserInfo(user.attributes['custom:db_id']);
            setAuth(userInfo);
            if (userInfo.avatar !== null && profilePicture === null) {
              const customPrefix = { public: 'public/media/' };
              const imageLink = await Storage.get(
                `avatars/${userInfo.id}/${userInfo.avatar.id}.${userInfo.avatar.extension}`,
                {
                  contentType: `image/${userInfo.avatar.fileType}`,
                  customPrefix
                }
              );
              setProfilePicture({
                fileType: userInfo.avatar.fileType,
                fileName: userInfo.avatar.fileName,
                extension: userInfo.avatar.extension,
                id: userInfo.avatar.id,
                contentUrl: imageLink,
                user: userInfo
              });
            }
          }
        } catch (e) {
        } finally {
          setLoading(false);
        }
      })();
    }, []);

    const collapsedValue = collapsed ?? !mounted;

    return (
      <StyledSidebar
        ref={ref}
        rootStyles={rootStyles}
        width={width}
        collapsedWidth={collapsedWidth}
        transitionDuration={transitionDuration}
        className={classnames(
          sidebarClasses.root,
          {
            [sidebarClasses.collapsed]: collapsedValue
          },
          className
        )}
        {...rest}
      >
        <StyledSidebarContainer className={sidebarClasses.container} backgroundColor={backgroundColor}>
          <Scrollbar>
            {children}
            <SidebarMenu wide={!collapsedValue} />
          </Scrollbar>
        </StyledSidebarContainer>
      </StyledSidebar>
    );
  }
);

export default Sidebar;
