import {
  FaultCategory,
  OperationalFaultType,
  RelatedEntityType,
  VisitFaultModel
} from '../models/types/visits-faults.types';
import { FaultData } from '../models/types/faults.types';
import { FaultsDashboardData } from '../models/types/faults-dashboard.types';

const conditionalSeparator = (result: string) => {
  if (result.length) return ' - ';
  return '';
};

export const composeVisitFaultName = (visitFault: VisitFaultModel | FaultsDashboardData | FaultData): string => {
  const { faultCategory, faultType, customName, relatedEntityType, relatedEntityName } = visitFault;
  let result: string = '';

  if (faultCategory === FaultCategory.Technical) {
    if (faultType) result += `${conditionalSeparator(result)}${faultType}`;
    if (relatedEntityType) {
      if (relatedEntityType === RelatedEntityType.Other) {
        if (customName) result += `${conditionalSeparator(result)}${customName}`;
      } else {
        result += ` - ${relatedEntityType}`;
        if (relatedEntityName) result += `${conditionalSeparator(result)}${relatedEntityName}`;
      }
    }
  } else {
    if (faultType === OperationalFaultType.Other) {
      if (customName) result += `${conditionalSeparator(result)}${customName}`;
    } else {
      if (faultType) result += `${conditionalSeparator(result)}${faultType}`;
    }
  }

  return result;
};
