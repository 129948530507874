import { createContext, FC, useState } from 'react';
import { VisitDashboardCommentModel } from 'src/models/types/comments.types';
import { GeneralBlock } from 'src/models/types/DashboardBlock/general.types';
import { PreCleanBlock } from 'src/models/types/DashboardBlock/pre-clean.types';
import { TemperaturesBlock } from 'src/models/types/DashboardBlock/temperatures.types';
import { GlassWashersBlock } from 'src/models/types/DashboardBlock/glass-washers.types';
import { HygieneBlock } from 'src/models/types/DashboardBlock/hygiene.types';
import { WastageBlock } from 'src/models/types/DashboardBlock/wastage.types';
import { FaultsBlock } from 'src/models/types/DashboardBlock/faults.types';

interface IVisitDashboardContext {
  dashboardsUpdate: boolean;
  toggleDashboardsUpdate: () => void;
  comments: VisitDashboardCommentModel[] | null;
  setComments: (v: VisitDashboardCommentModel[]) => void;
  generalBlock: GeneralBlock | null;
  setGeneralBlock: (v: GeneralBlock) => void;
  generalBlockWithTaint: GeneralBlock | null | undefined;
  setGeneralBlockWithTaint: (v: GeneralBlock | undefined) => void;
  winterisationGeneralBlock: GeneralBlock | null | undefined;
  setWinterisationGeneralBlock: (v: GeneralBlock | null | undefined) => void;
  dewinterisationGeneralBlock: GeneralBlock | null | undefined;
  setDewinterisationGeneralBlock: (v: GeneralBlock | null | undefined) => void;
  chillPourGeneralBlock: GeneralBlock | undefined | null;
  setChillPourGeneralBlock: (v: GeneralBlock | undefined | null) => void;
  preCleanBlock: PreCleanBlock | null;
  setPreCleanBlock: (v: PreCleanBlock) => void;
  chillPourPreCleanBlock: PreCleanBlock | undefined | null;
  setChillPourPreCleanBlock: (v: PreCleanBlock | undefined | null) => void;
  temperaturesWithTrendsBlock: TemperaturesBlock | null;
  setTemperaturesWithTrendsBlock: (v: TemperaturesBlock) => void;
  temperaturesBlock: TemperaturesBlock | null | undefined;
  setTemperaturesBlock: (v: TemperaturesBlock | null | undefined) => void;
  glassWashersBlock: GlassWashersBlock | null;
  setGlassWashersBlock: (v: GlassWashersBlock) => void;
  hygieneBlock: HygieneBlock | null;
  setHygieneBlock: (v: HygieneBlock) => void;
  sosBaseHygieneBlock: HygieneBlock | null | undefined;
  setSosBaseHygieneBlock: (v: HygieneBlock | undefined | null) => void;
  sos2HygieneBlock: HygieneBlock | null | undefined;
  setSos2HygieneBlock: (v: HygieneBlock | null | undefined) => void;
  chillPourHygieneBlock: HygieneBlock | null | undefined;
  setChillPourHygieneBlock: (v: HygieneBlock | null | undefined) => void;
  wastageBlockWithGraph: WastageBlock | null;
  setWastageBlockWithGraph: (v: WastageBlock) => void;
  wastageBlock: WastageBlock | null | undefined;
  setWastageBlock: (v: WastageBlock | null | undefined) => void;
  baseWastageBlock: WastageBlock | null | undefined;
  setBaseWastageBlock: (v: WastageBlock | null | undefined) => void;
  faultsBlock: FaultsBlock | null;
  setFaultsBlock: (v: FaultsBlock) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
}

export const VisitDashboardContext = createContext<IVisitDashboardContext>({} as IVisitDashboardContext);

export const VisitDashboardProvider: FC = ({ children }) => {
  const [dashboardsUpdate, setDashboardsUpdate] = useState(false);
  const [comments, setComments] = useState<VisitDashboardCommentModel[] | null>(null);
  const [generalBlock, setGeneralBlock] = useState<GeneralBlock | null>(null);
  const [generalBlockWithTaint, setGeneralBlockWithTaint] = useState<GeneralBlock | null | undefined>(null);
  const [winterisationGeneralBlock, setWinterisationGeneralBlock] = useState<GeneralBlock | null | undefined>(null);
  const [dewinterisationGeneralBlock, setDewinterisationGeneralBlock] = useState<GeneralBlock | null | undefined>(null);
  const [chillPourGeneralBlock, setChillPourGeneralBlock] = useState<GeneralBlock | null | undefined>(null);
  const [preCleanBlock, setPreCleanBlock] = useState<PreCleanBlock | null>(null);
  const [chillPourPreCleanBlock, setChillPourPreCleanBlock] = useState<PreCleanBlock | undefined | null>(null);
  const [temperaturesWithTrendsBlock, setTemperaturesWithTrendsBlock] = useState<TemperaturesBlock | null>(null);
  const [temperaturesBlock, setTemperaturesBlock] = useState<TemperaturesBlock | null | undefined>(null);
  const [glassWashersBlock, setGlassWashersBlock] = useState<GlassWashersBlock | null>(null);
  const [hygieneBlock, setHygieneBlock] = useState<HygieneBlock | null>(null);
  const [sosBaseHygieneBlock, setSosBaseHygieneBlock] = useState<HygieneBlock | null | undefined>(null);
  const [sos2HygieneBlock, setSos2HygieneBlock] = useState<HygieneBlock | null | undefined>(null);
  const [chillPourHygieneBlock, setChillPourHygieneBlock] = useState<HygieneBlock | null | undefined>(null);
  const [wastageBlockWithGraph, setWastageBlockWithGraph] = useState<WastageBlock | null>(null);
  const [wastageBlock, setWastageBlock] = useState<WastageBlock | null | undefined>(null);
  const [baseWastageBlock, setBaseWastageBlock] = useState<WastageBlock | null | undefined>(null);
  const [faultsBlock, setFaultsBlock] = useState<FaultsBlock | null>(null);
  const [loading, setLoading] = useState(false);

  const toggleUpdate = (): void => setDashboardsUpdate(!dashboardsUpdate);

  return (
    <VisitDashboardContext.Provider
      value={{
        dashboardsUpdate,
        toggleDashboardsUpdate: toggleUpdate,
        comments,
        setComments,
        generalBlock,
        setGeneralBlock,
        generalBlockWithTaint,
        setGeneralBlockWithTaint,
        winterisationGeneralBlock,
        setWinterisationGeneralBlock,
        dewinterisationGeneralBlock,
        setDewinterisationGeneralBlock,
        chillPourGeneralBlock,
        setChillPourGeneralBlock,
        preCleanBlock,
        setPreCleanBlock,
        chillPourPreCleanBlock,
        setChillPourPreCleanBlock,
        temperaturesBlock,
        setTemperaturesBlock,
        temperaturesWithTrendsBlock,
        setTemperaturesWithTrendsBlock,
        glassWashersBlock,
        setGlassWashersBlock,
        hygieneBlock,
        setHygieneBlock,
        sosBaseHygieneBlock,
        setSosBaseHygieneBlock,
        sos2HygieneBlock,
        setSos2HygieneBlock,
        chillPourHygieneBlock,
        setChillPourHygieneBlock,
        wastageBlockWithGraph,
        setWastageBlockWithGraph,
        wastageBlock,
        setWastageBlock,
        baseWastageBlock,
        setBaseWastageBlock,
        faultsBlock,
        setFaultsBlock,
        loading,
        setLoading
      }}
    >
      {children}
    </VisitDashboardContext.Provider>
  );
};
