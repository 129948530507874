import { createContext, FC, useState } from 'react';
import { UserFiltersProps, UserModel, UserSortingFields } from '../models/types/users.types';
import { Order } from '../models/types/common.types';
import { RoleModel } from 'src/models/types/roles.types';
import { ClientModel } from '../models/types/clients.types';
import { VenueModel } from '../models/types/venues.types';

interface IUsersContext {
  usersUpdate: boolean;
  toggleUsersUpdate: () => void;
  users: UserModel[];
  setUsers: (val: UserModel[]) => void;
  clients: ClientModel[];
  setClients: (val: ClientModel[]) => void;
  page: number;
  setPage: (val: number) => void;
  limit: number;
  setLimit: (val: number) => void;
  count: number;
  setCount: (val: number) => void;
  order: Order;
  setOrder: (val: Order) => void;
  orderBy: UserSortingFields;
  setOrderBy: (val: UserSortingFields) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  setRoles: (val: RoleModel[]) => void;
  roles: RoleModel[];
  venues: VenueModel[];
  setVenues: (val: VenueModel[]) => void;
  setFilters: (val: UserFiltersProps) => void;
  filters: UserFiltersProps;
  resetFilters: () => void;
}

export const UsersContext = createContext<IUsersContext>({} as IUsersContext);

export const UsersProvider: FC = ({ children }) => {
  const [usersUpdate, setUsersUpdate] = useState(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<UserSortingFields>('firstName');
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState<RoleModel[]>([]);
  const [venues, setVenues] = useState<VenueModel[]>([]);
  const emptyFilers: UserFiltersProps = {
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    mobilePhone: undefined,
    employmentStatus: [],
    jobRole: [],
    groups: [],
    roles: []
  };
  const [filters, setFilters] = useState<UserFiltersProps>(emptyFilers);
  const resetFilters = (): void => setFilters(emptyFilers);
  const toggleUpdate = (): void => setUsersUpdate(!usersUpdate);

  return (
    <UsersContext.Provider
      value={{
        usersUpdate,
        resetFilters,
        toggleUsersUpdate: toggleUpdate,
        users,
        setUsers,
        clients,
        setClients,
        page,
        setPage,
        limit,
        setLimit,
        count,
        setCount,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        loading,
        setLoading,
        roles,
        setRoles,
        filters,
        setFilters,
        venues,
        setVenues
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};
