import React from 'react';

import { Theme } from '@mui/material';
import { AvaniLightTheme } from './schemes/AvaniLightTheme';
import { AvaniDarkTheme } from './schemes/AvaniDarkTheme';

export function themeCreator(theme: string): Theme {
  return themeMap[theme];
}

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      avani: {
        teal: string;
        turc: string;
        magenta: string;
        lightTurc: string;
        lightMagenta: string;
        darkTitleText: string;
        aGreen: string;
        aGrey: string;
        aYellow: string;
        aPurple: string;
        aDarkPink: string;
      };
    };
    general: {
      reactFrameworkColor: React.CSSProperties['color'];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    sidebar: {
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      width: string;
      textColor: React.CSSProperties['color'];
      dividerBg: React.CSSProperties['color'];
      menuItemColor: React.CSSProperties['color'];
      menuItemColorActive: React.CSSProperties['color'];
      menuItemBg: React.CSSProperties['color'];
      menuItemBgActive: React.CSSProperties['color'];
      menuItemIconColor: React.CSSProperties['color'];
      menuItemIconColorActive: React.CSSProperties['color'];
      menuItemHeadingColor: React.CSSProperties['color'];
    };
    header: {
      height: string;
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      textColor: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    colors: {
      gradients: {
        blue1: string;
        blue2: string;
        blue3: string;
        blue4: string;
        blue5: string;
        orange1: string;
        orange2: string;
        orange3: string;
        purple1: string;
        purple3: string;
        pink1: string;
        pink2: string;
        green1: string;
        green2: string;
        black1: string;
        black2: string;
      };
      shadows: {
        success: string;
        error: string;
        primary: string;
        warning: string;
        info: string;
      };
      alpha: {
        white: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        trueWhite: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
        black: {
          5: string;
          10: string;
          30: string;
          50: string;
          70: string;
          100: string;
        };
      };
      secondary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      primary: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      success: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      warning: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      error: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      info: {
        lighter: string;
        light: string;
        main: string;
        dark: string;
      };
      avani: {
        teal: string;
        turc: string;
        magenta: string;
        lightTurc: string;
        lightMagenta: string;
        darkTitleText: string;
        aGreen: string;
        aGrey: string;
        aYellow: string;
        aPurple: string;
        aDarkPink: string;
      };
    };

    general: {
      reactFrameworkColor: React.CSSProperties['color'];
      borderRadiusSm: string;
      borderRadius: string;
      borderRadiusLg: string;
      borderRadiusXl: string;
    };
    sidebar: {
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      width: string;
      textColor: React.CSSProperties['color'];
      dividerBg: React.CSSProperties['color'];
      menuItemColor: React.CSSProperties['color'];
      menuItemColorActive: React.CSSProperties['color'];
      menuItemBg: React.CSSProperties['color'];
      menuItemBgActive: React.CSSProperties['color'];
      menuItemIconColor: React.CSSProperties['color'];
      menuItemIconColorActive: React.CSSProperties['color'];
      menuItemHeadingColor: React.CSSProperties['color'];
    };
    header: {
      height: string;
      background: React.CSSProperties['color'];
      boxShadow: React.CSSProperties['color'];
      textColor: React.CSSProperties['color'];
    };
  }

  interface Palette {
    aTeal: Palette['primary'];
    aTurc: Palette['primary'];
    aMagenta: Palette['primary'];
    aLightTurc: Palette['primary'];
    aLightMagenta: Palette['primary'];
    aDarkTitleText: Palette['primary'];
    aYellow: Palette['primary'];
    aGreen: Palette['primary'];
    aGrey: Palette['primary'];
    aDarkPink: Palette['primary'];
    aPurple: Palette['primary'];
  }

  interface PaletteOptions {
    aTeal: PaletteOptions['primary'];
    aTurc: PaletteOptions['primary'];
    aMagenta: PaletteOptions['primary'];
    aLightTurc: PaletteOptions['primary'];
    aLightMagenta: PaletteOptions['primary'];
    aDarkTitleText: PaletteOptions['primary'];
    aYellow: PaletteOptions['primary'];
    aGreen: PaletteOptions['primary'];
    aGrey: PaletteOptions['primary'];
    aDarkPink: PaletteOptions['primary'];
    aPurple: PaletteOptions['primary'];
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    aTeal: true;
    aTurc: true;
    aMagenta: true;
    aLightTurc: true;
    aLightMagenta: true;
    aDarkTitleText: true;
    aYellow: true;
    aGreen: true;
    aGrey: true;
    aDarkPink: true;
    aPurple: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsColorOverrides {
    aTeal: true;
    aTurc: true;
    aMagenta: true;
    aLightTurc: true;
    aLightMagenta: true;
    aDarkTitleText: true;
    aYellow: true;
    aGreen: true;
    aGrey: true;
    aDarkPink: true;
    aPurple: true;
  }
}

declare module '@mui/material/Paper' {
  interface PaperPropsColorOverrides {
    aTeal: true;
    aTurc: true;
    aMagenta: true;
    aLightTurc: true;
    aLightMagenta: true;
    aDarkTitleText: true;
    aYellow: true;
    aGreen: true;
    aGrey: true;
    aDarkPink: true;
    aPurple: true;
  }
}

const themeMap: { [key: string]: Theme } = {
  AvaniLightTheme,
  AvaniDarkTheme
};
