import { createContext, FC, useEffect, useState } from 'react';
import { FaultsDashboardData, LastVisitFaultsType } from '../models/types/faults-dashboard.types';
import { readableDate } from '../utils';

interface IFaultsDashboardsContext {
  dashboardsUpdate: boolean;
  toggleDashboardsUpdate: () => void;
  totalUnresolved: number;
  setTotalUnresolved: (val: number) => void;
  lastVisitFaults: LastVisitFaultsType;
  setLastVisitFaults: (val: LastVisitFaultsType) => void;
  faults: FaultsDashboardData[];
  setFaults: (val: FaultsDashboardData[]) => void;
  filteredFaults: FaultsDashboardData[];
  setFilteredFaults: (val: FaultsDashboardData[]) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  venueNames: string[];
  setVenueNames: (val: string[]) => void;
  venueName: string | undefined;
  setVenueName: (val: string | undefined) => void;
  statuses: FaultStatusType[];
  setStatuses: (val: FaultStatusType[]) => void;
  status: FaultStatusType | undefined;
  setStatus: (val: FaultStatusType | undefined) => void;
  criticalities: boolean[];
  setCriticalities: (val: boolean[]) => void;
  criticality: boolean | undefined;
  setCriticality: (val: boolean | undefined) => void;
  dates: string[];
  setDates: (val: string[]) => void;
  date: string | undefined;
  setDate: (val: string | undefined) => void;
}

type FaultStatusType = 'Resolved' | 'Pending' | 'Unresolved';

export const FaultsDashboardsContext = createContext<IFaultsDashboardsContext>({} as IFaultsDashboardsContext);

export const FaultsDashboardsProvider: FC = ({ children }) => {
  const [dashboardsUpdate, setDashboardsUpdate] = useState(false);
  const [totalUnresolved, setTotalUnresolved] = useState(0);
  const [lastVisitFaults, setLastVisitFaults] = useState<LastVisitFaultsType>({ critical: 0, regular: 0 });
  const [faults, setFaults] = useState<FaultsDashboardData[]>([]);
  const [loading, setLoading] = useState(false);

  // Filters
  const [filteredFaults, setFilteredFaults] = useState<FaultsDashboardData[]>([]);
  const [venueNames, setVenueNames] = useState<string[]>([]);
  const [venueName, setVenueName] = useState<string>();

  const [statuses, setStatuses] = useState<FaultStatusType[]>([]);
  const [status, setStatus] = useState<FaultStatusType | undefined>();

  const [criticalities, setCriticalities] = useState<boolean[]>([]);
  const [criticality, setCriticality] = useState<boolean | undefined>();

  const [dates, setDates] = useState<string[]>([]);
  const [date, setDate] = useState<string | undefined>();

  const toggleUpdate = (): void => setDashboardsUpdate(!dashboardsUpdate);

  const extractVenueNames = (faults: FaultsDashboardData[]): string[] => {
    const allVenues = faults.flatMap(({ faultInteractions }) => faultInteractions.map(({ venueName }) => venueName));
    return Array.from(new Set(allVenues));
  };

  const extractStatuses = (faults: FaultsDashboardData[]): FaultStatusType[] => {
    const statuses: FaultStatusType[] = [];
    if (faults.some(({ isResolved }) => isResolved)) statuses.push('Resolved');
    if (faults.some(({ isPendingRevision }) => isPendingRevision)) statuses.push('Pending');
    if (faults.some(({ isResolved }) => !isResolved)) statuses.push('Unresolved');
    return statuses;
  };

  const extractCriticalities = (faults: FaultsDashboardData[]): boolean[] => {
    const criticalities: boolean[] = [];
    if (faults.some(({ isCritical }) => isCritical)) criticalities.push(true);
    if (faults.some(({ isCritical }) => !isCritical)) criticalities.push(false);
    return criticalities;
  };

  const extractDates = (faults: FaultsDashboardData[]): string[] => {
    const allFaultsVisitDates = faults.flatMap((fault) =>
      fault.faultInteractions.map(({ startedAt }) => readableDate(startedAt, true))
    );
    return Array.from(new Set(allFaultsVisitDates));
  };

  // Set filter options
  useEffect(() => {
    setFilteredFaults(faults);
    setVenueNames(extractVenueNames(faults));
    setStatuses(extractStatuses(faults));
    setCriticalities(extractCriticalities(faults));
    setDates(extractDates(faults));
  }, [faults]);

  useEffect(() => {
    if (!faults?.length) return;
    setFilteredFaults(
      faults
        .filter(({ faultInteractions }) => {
          if (date !== undefined) {
            return faultInteractions.some(({ startedAt }) => readableDate(startedAt, true) === date);
          } else return true;
        })
        .filter(({ faultInteractions }) => {
          if (venueName !== undefined) {
            return faultInteractions.some((fi) => fi.venueName === venueName);
          } else return true;
        })
        .filter((fault) => {
          switch (status) {
            case 'Resolved':
              return fault.isResolved;
            case 'Pending':
              return fault.isPendingRevision;
            case 'Unresolved':
              return !fault.isResolved;
            default:
              return true;
          }
        })
        .filter((fault) => {
          if (criticality !== undefined) {
            return fault.isCritical === criticality;
          } else return true;
        })
    );
  }, [venueName, status, criticality, date]);

  return (
    <FaultsDashboardsContext.Provider
      value={{
        dashboardsUpdate,
        toggleDashboardsUpdate: toggleUpdate,
        totalUnresolved,
        setTotalUnresolved,
        lastVisitFaults,
        setLastVisitFaults,
        faults,
        setFaults,
        filteredFaults,
        setFilteredFaults,
        loading,
        setLoading,
        venueName,
        setVenueName,
        venueNames,
        setVenueNames,
        statuses,
        setStatuses,
        status,
        setStatus,
        criticalities,
        setCriticalities,
        criticality,
        setCriticality,
        dates,
        setDates,
        date,
        setDate
      }}
    >
      {children}
    </FaultsDashboardsContext.Provider>
  );
};
