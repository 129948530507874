import React, { createContext, FC, useEffect, useState } from 'react';

type SidebarContext = {
  collapsed: boolean;
  setCollapsed: (val: boolean) => void;
  sidebarToggle: any;
  toggleSubMenu: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
  changeUsersSubMenuToggle: () => void;
  toggleBrewersTSPsSubMenu: boolean;
  changeBrewersTSPsSubMenuToggle: () => void;
  toggleReportsSubMenu: boolean;
  changeReportsSubMenuToggle: () => void;
  isCollapsed: () => boolean;
};

export const SidebarContext = createContext<SidebarContext>({} as SidebarContext);

export const SidebarProvider: FC = ({ children }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [toggleSubMenu, setToggleSubMenu] = useState(false);
  const [toggleBrewersTSPsSubMenu, setToggleBrewersTSPsSubMenu] = useState(false);
  const [toggleReportsSubMenu, setToggleReportsSubMenu] = useState(false);
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const closeSidebar = () => {
    setSidebarToggle(false);
  };

  const isCollapsed = (): boolean => {
    return collapsed;
  };

  const changeUsersSubMenuToggle = () => {
    setToggleSubMenu(!toggleSubMenu);
    setToggleBrewersTSPsSubMenu(toggleBrewersTSPsSubMenu);
  };

  const changeBrewersTSPsSubMenuToggle = () => {
    setToggleBrewersTSPsSubMenu(!toggleBrewersTSPsSubMenu);
  };

  const changeReportsSubMenuToggle = () => {
    setToggleReportsSubMenu(!toggleReportsSubMenu);
  };

  useEffect(() => {
    const isCollapsed = localStorage.getItem('sidebarCollapsed') === 'false';
    setCollapsed(!isCollapsed);
  }, []);

  useEffect(() => {
    localStorage.setItem('sidebarCollapsed', String(collapsed));
  }, [collapsed]);

  return (
    <>
      <SidebarContext.Provider
        value={{
          collapsed,
          setCollapsed,
          sidebarToggle,
          toggleSubMenu,
          changeUsersSubMenuToggle,
          toggleBrewersTSPsSubMenu,
          changeBrewersTSPsSubMenuToggle,
          toggleReportsSubMenu,
          changeReportsSubMenuToggle,
          toggleSidebar,
          closeSidebar,
          isCollapsed
        }}
      >
        {children}
      </SidebarContext.Provider>
    </>
  );
};
